import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client'
import { AuthContext } from '../../AuthContext'
import { Chart, ArcElement, registerables } from 'chart.js';

import { Bar, Line } from 'react-chartjs-2';
import { AlertApp } from '../Global/AlertApp';

import { GET_WAREHOUSE_KPIS, GET_ALL_WAREHOUSE_KPIS } from '../../graphql/QueryManager'
import { Col, Row } from 'reactstrap';


const WarehousesHome = ({ whsCode }) => {
  const { user: _user } = useContext(AuthContext)
  var currentWeekNumber = require('current-week-number')
  const [performanceIndicators, setPerformanceIndicator] = useState([])
  const [deliveryCounts, setDeliveryCounts] = useState([])
  const [deliveryCountsMonth, setDeliveryCountsMonth] = useState([])

  const [deliveryTimes, setDeliveryTimes] = useState([])
  const [whsName, setWhsNme] = useState('')
  const [tabIndex, setTabIndex] = useState(1)
  const [tabIndexR, setTabIndexR] = useState(1)

  const { loading, error, data } = useQuery(GET_WAREHOUSE_KPIS, {
    fetchPolicy: 'network-only',
    variables: {
      code: whsCode,
      week: [50, 51, 52, 53, 54, 1, 2, 3, 4]
    }
  })

  const { loading: loadingKPIS, error: errorKPIS, data: dataKPIS } = useQuery(GET_ALL_WAREHOUSE_KPIS, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!loading) {
      if (error) {
        AlertApp({ message: error, type: 'errorToast' })
      } else {
        setPerformanceIndicator(data.warehouseKPIS)
        setWhsNme(data.warehouseKPIS[0].whsName)
      }
    }
  }, [loading, error, data])

  useEffect(() => {
    if (!loadingKPIS) {
      if (errorKPIS) {
        AlertApp({ message: error, type: 'errorToast' })
      } {
        if (dataKPIS) {
          let list = []
          let list2 = []
          let list3 = []
          dataKPIS.allKpiWarehouse.map((element) => {
            if (element.name === "Tiempos de entrega") {
              if (element.year === 2024) {
                list.push(element)
              }
              if (element.month === 1 && element.year === 2025) {
                list3.push(element)
              }
            }
            if (element.name === "Tiempos de entrega (Min)") {
              list2.push(element)
            }
          })
          console.log(list3)
          setDeliveryCounts(list)
          setDeliveryTimes(list2)
          setDeliveryCountsMonth(list3)
        }
      }
    }
  }, [loadingKPIS, errorKPIS, dataKPIS])

  Chart.register(ArcElement)
  Chart.register(...registerables);

  function getValueKPI(id) {
    if (performanceIndicators.some((element) => element.id == `${currentWeekNumber(new Date())}${id}`)) {
      const indicator = performanceIndicators.find((element) => element.id == `${currentWeekNumber(new Date())}${id}`)
      return indicator.kpi_Value
    }

  }
  function getUnitKPI(id) {
    if (performanceIndicators.some((element) => element.id == `${currentWeekNumber(new Date())}${id}`)) {
      const indicator = performanceIndicators.find((element) => element.id == `${currentWeekNumber(new Date())}${id}`)
      return `${indicator.kpi_Unit} - ${indicator.kpi_Value}`
    } else {
      return `${currentWeekNumber(new Date())}${id}`
    }
  }
  const Months = {
    1: '01 Enero',
    2: '02 Febrero',
    3: '03 Marzo',
    4: '04 Abril',
    5: '05 Mayo',
    6: '06 Junio',
    7: '07 Julio',
    8: '08 Agosto',
    9: '09 Septiembre',
    10: '10 Octubre',
    11: '11 Noviembre',
    12: '12 Diciembre'
  }
  const MonthsLabels = {
    1: 'Enero',
    2: 'Febrero',
    3: 'Marzo',
    4: 'Abril',
    5: 'Mayo',
    6: 'Junio',
    7: 'Julio',
    8: 'Agosto',
    9: 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre'
  }
  return (
    <>
      <Row>
        {
          performanceIndicators.map((element, index) => {

            if (element.week === currentWeekNumber(new Date())) {
              if (element.kpi !== 'VSR')
                if (element.kpi !== 'HTS')
                  if (element.kpi !== 'TPP')
                    if (element.kpi !== 'MT')
                      if (element.kpi !== 'MP')
                        return <Col lg={2} key={`${element.id}-${index}`}>
                          <div class="info-box">
                            <span class="info-box-icon bg-info elevation-1"><i class="ion ion-stats-bars"></i></span>
                            <div class="info-box-content">
                              <span class="info-box-text">{`${element.kpi_Name}`}</span>
                              <span class="info-box-number">
                                {parseFloat(element.kpi_Value).toFixed(2)}
                              </span>
                            </div>
                          </div>
                        </Col>

            }
          })
        }
      </Row>
      <Row>
        <Col>
          <div className="card bg-dark">
            <div className="card-header">
              <h5 className="card-title">Resumen mensual</h5>

              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus"></i>
                </button>
                <div className="btn-group">
                  <button type="button" className="btn btn-tool dropdown-toggle" data-toggle="dropdown">
                    <i className="fas fa-wrench"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-right" role="menu">
                    <a href="#" className="dropdown-item">Action</a>
                    <a href="#" className="dropdown-item">Another action</a>
                    <a href="#" className="dropdown-item">Something else here</a>
                    <a className="dropdown-divider"></a>
                    <a href="#" className="dropdown-item">Separated link</a>
                  </div>
                </div>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-8">
                  <p className="text-center">
                    <strong>Tiempos de almacen  (Enero)</strong>
                  </p>

                  <div className="chart" style={{ height: "80%" }}>
                    {/* <!-- Sales Chart Canvas --> */}
                    <Bar

                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        legend: {
                          display: true
                        },

                      }}
                      data={
                        {
                          labels: [...new Set(deliveryCounts.filter((e) => (e.branch !== "GEN")).map((element) => element.branch))],
                          datasets: [
                            {
                              label: 'Pedidos a tiempo',
                              backgroundColor: 'rgba(210,214,222,255)',
                              borderColor: 'rgba(210, 214, 222, 1)',
                              // fill: true,
                              data: deliveryCountsMonth.filter((e) => (e.branch !== "GEN")).map((element) => element.inRange)
                            },
                            {
                              label: 'Pedidos tarde',
                              backgroundColor: 'rgba(81,149,191,255)',
                              borderColor: 'rgba(7, 109, 72)',
                              // fill: true,
                              data: deliveryCountsMonth.filter((e) => (e.branch !== "GEN")).map((element) => element.outRange)
                            },
                          ]
                        }
                      }
                    />
                  </div>
                  {/* <!-- /.chart-responsive --> */}
                </div>
                {/* <!-- /.col --> */}
                <div className="col-md-4">
                  <p className="text-center">
                    <strong>Pedidos a tiempo / Total</strong>
                  </p>
                  {deliveryCountsMonth.filter((e) => (e.branch !== "GEN")).map((element) => (
                    <div key={element.key} className="progress-group">
                      {`Cumplimiento - ${element.branch}`}
                      <span className="float-right"><b>{element.inRange}</b>/{element.value}</span>
                      <div className="progress progress-sm">
                        <div className={`progress-bar bg-${element.brachColor}`} style={{ width: `${(element.inRange / element.value) * 100}%` }}></div>
                      </div>
                    </div>
                  ))}
                  {/* <!-- /.progress-group --> */}
                </div>
                {/* <!-- /.col --> */}
              </div>
              {/* <!-- /.row --> */}
            </div>
            <div className="card-footer">

              {deliveryCountsMonth.filter((e) => (e.branch === "GEN")).map((element) => (
                <div className="row">
                  <div key={element.key} className="col-12 col-sm-6 col-md-3">
                    <div className="description-block border-right">
                      <span className="description-percentage text-info"><i className="fas fa-caret-up"></i> 100%</span>
                      <h5 className="description-header">{element.value}</h5>
                      <span className="description-text">TOTAL PEDIDOS</span>
                    </div>
                    {/* <!-- /.description-block --> */}
                  </div>
                  <div className="col-12 col-sm-6 col-md-3">
                    <div className="description-block border-right">
                      <span className="description-percentage text-success"><i className="fas fa-caret-up"></i> {`${((element.inRange / element.value) * 100).toFixed(2)}%`}</span>
                      <h5 className="description-header">{element.inRange}</h5>
                      <span className="description-text">TOTAL TIEMPO</span>
                    </div>
                    {/* <!-- /.description-block --> */}
                  </div>
                  <div className="col-12 col-sm-6 col-md-3">
                    <div className="description-block">
                      <span className="description-percentage text-danger"><i className="fas fa-caret-down"></i> {`${((element.outRange / element.value) * 100).toFixed(2)}%`}</span>
                      <h5 className="description-header">{element.outRange}</h5>
                      <span className="description-text">TOTAL TARDE</span>
                    </div>

                  </div>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="card">
            <div className="card-header bg-dark">
              <h5 className="card-title">Resumen anual (2024)</h5>

              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus"></i>
                </button>
                <div className="btn-group">
                  <button type="button" className="btn btn-tool dropdown-toggle" data-toggle="dropdown">
                    <i className="fas fa-calendar"></i> Mes - {MonthsLabels[tabIndex]}
                  </button>
                  <div className="dropdown-menu dropdown-menu-right" role="menu">
                    <a href="#" className="dropdown-item" onClick={() => setTabIndex('GEN')}>General</a>
                    {[...new Set(deliveryCounts.map((element) => element.month))].map(e => (
                      <a className="dropdown-item" onClick={() => setTabIndex(e)}>{Months[e]}</a>
                    ))}
                  </div>
                </div>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-8">
                  <p className="text-center">
                    <strong>Tiempos de almacen ({MonthsLabels[tabIndex]})</strong>
                  </p>

                  <div className="chart" style={{ height: "80%" }}>
                    {/* <!-- Sales Chart Canvas --> */}
                    <Line

                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        legend: {
                          display: true
                        },

                      }}
                      data={
                        {
                          labels: [...new Set(deliveryCounts.map((element) => Months[element.month]))],
                          datasets: [
                            {
                              label: 'GDL',
                              // backgroundColor: 'rgba(210,214,222,255)',
                              borderColor: 'rgba(45,163,183,255)',
                              fill: true,
                              data: deliveryCounts.filter((e) => e.branch === "GDL").map((element) => element.value)
                            },
                            {
                              label: 'CHI',
                              // backgroundColor: 'rgba(210,214,222,255)',
                              borderColor: 'rgba(53,167,73,255)',
                              fill: true,
                              data: deliveryCounts.filter((e) => e.branch === "CHI").map((element) => element.value)
                            },
                            {
                              label: 'MEX',
                              // backgroundColor: 'rgba(210,214,222,255)',
                              borderColor: 'rgba(102,33,239,255)',
                              fill: true,
                              data: deliveryCounts.filter((e) => e.branch === "MEX").map((element) => element.value)
                            },
                            {
                              label: 'MTY',
                              // backgroundColor: 'rgba(210,214,222,255)',
                              borderColor: 'rgba(3,32,62,255)',
                              fill: true,
                              data: deliveryCounts.filter((e) => e.branch === "MTY").map((element) => element.value)
                            },
                            {
                              label: 'NOG',
                              // backgroundColor: 'rgba(210,214,222,255)',
                              borderColor: 'rgba(212,19,96,255)',
                              fill: true,
                              data: deliveryCounts.filter((e) => e.branch === "NOG").map((element) => element.value)
                            },
                            {
                              label: 'QRO',
                              // backgroundColor: 'rgba(210,214,222,255)',
                              borderColor: 'rgba(249,123,35,255)',
                              fill: true,
                              data: deliveryCounts.filter((e) => e.branch === "QRO").map((element) => element.value)
                            },
                            {
                              label: 'TIJ',
                              // backgroundColor: 'rgba(210,214,222,255)',
                              borderColor: 'rgba(68,153,113,255)',
                              fill: true,
                              data: deliveryCounts.filter((e) => e.branch === "TIJ").map((element) => element.value)
                            },
                          ]
                        }
                      }
                    />
                  </div>
                  {/* <!-- /.chart-responsive --> */}
                </div>
                {/* <!-- /.col --> */}
                <div className="col-md-4">
                  <p className="text-center">
                    <strong>Pedidos a tiempo / Total</strong>
                  </p>
                  {tabIndex === 1 && (
                    deliveryCounts.filter((e) => e.month === tabIndex).map((element) => (
                      <div key={element.key} className="progress-group">
                        {`${element.name} - ${element.branch}`}
                        <span className="float-right"><b>{element.inRange}</b>/{element.value}</span>
                        <div className="progress progress-sm">
                          <div className={`progress-bar bg-${element.brachColor}`} style={{ width: `${(element.inRange / element.value) * 100}%` }}></div>
                        </div>
                      </div>
                    ))
                  )}
                  {tabIndex === 2 && (
                    deliveryCounts.filter((e) => e.month === tabIndex).map((element) => (
                      <div key={element.key} className="progress-group">
                        {`${element.name} - ${element.branch}`}
                        <span className="float-right"><b>{element.inRange}</b>/{element.value}</span>
                        <div className="progress progress-sm">
                          <div className={`progress-bar bg-${element.brachColor}`} style={{ width: `${(element.inRange / element.value) * 100}%` }}></div>
                        </div>
                      </div>
                    ))
                  )}
                  {tabIndex === 3 && (
                    deliveryCounts.filter((e) => e.month === tabIndex).map((element) => (
                      <div key={element.key} className="progress-group">
                        {`${element.name} - ${element.branch}`}
                        <span className="float-right"><b>{element.inRange}</b>/{element.value}</span>
                        <div className="progress progress-sm">
                          <div className={`progress-bar bg-${element.brachColor}`} style={{ width: `${(element.inRange / element.value) * 100}%` }}></div>
                        </div>
                      </div>
                    ))
                  )}
                  {tabIndex === 4 && (
                    deliveryCounts.filter((e) => e.month === tabIndex).map((element) => (
                      <div key={element.key} className="progress-group">
                        {`${element.name} - ${element.branch}`}
                        <span className="float-right"><b>{element.inRange}</b>/{element.value}</span>
                        <div className="progress progress-sm">
                          <div className={`progress-bar bg-${element.brachColor}`} style={{ width: `${(element.inRange / element.value) * 100}%` }}></div>
                        </div>
                      </div>
                    ))
                  )}
                  {tabIndex === 5 && (
                    deliveryCounts.filter((e) => e.month === tabIndex).map((element) => (
                      <div key={element.key} className="progress-group">
                        {`${element.name} - ${element.branch}`}
                        <span className="float-right"><b>{element.inRange}</b>/{element.value}</span>
                        <div className="progress progress-sm">
                          <div className={`progress-bar bg-${element.brachColor}`} style={{ width: `${(element.inRange / element.value) * 100}%` }}></div>
                        </div>
                      </div>
                    ))
                  )}
                  {tabIndex === 6 && (
                    deliveryCounts.filter((e) => e.month === tabIndex).map((element) => (
                      <div key={element.key} className="progress-group">
                        {`${element.name} - ${element.branch}`}
                        <span className="float-right"><b>{element.inRange}</b>/{element.value}</span>
                        <div className="progress progress-sm">
                          <div className={`progress-bar bg-${element.brachColor}`} style={{ width: `${(element.inRange / element.value) * 100}%` }}></div>
                        </div>
                      </div>
                    ))
                  )}
                  {tabIndex === 7 && (
                    deliveryCounts.filter((e) => e.month === tabIndex).map((element) => (
                      <div key={element.key} className="progress-group">
                        {`${element.name} - ${element.branch}`}
                        <span className="float-right"><b>{element.inRange}</b>/{element.value}</span>
                        <div className="progress progress-sm">
                          <div className={`progress-bar bg-${element.brachColor}`} style={{ width: `${(element.inRange / element.value) * 100}%` }}></div>
                        </div>
                      </div>
                    ))
                  )}
                  {tabIndex === 8 && (
                    deliveryCounts.filter((e) => e.month === tabIndex).map((element) => (
                      <div key={element.key} className="progress-group">
                        {`${element.name} - ${element.branch}`}
                        <span className="float-right"><b>{element.inRange}</b>/{element.value}</span>
                        <div className="progress progress-sm">
                          <div className={`progress-bar bg-${element.brachColor}`} style={{ width: `${(element.inRange / element.value) * 100}%` }}></div>
                        </div>
                      </div>
                    ))
                  )}
                  {tabIndex === 9 && (
                    deliveryCounts.filter((e) => e.month === tabIndex).map((element) => (
                      <div key={element.key} className="progress-group">
                        {`${element.name} - ${element.branch}`}
                        <span className="float-right"><b>{element.inRange}</b>/{element.value}</span>
                        <div className="progress progress-sm">
                          <div className={`progress-bar bg-${element.brachColor}`} style={{ width: `${(element.inRange / element.value) * 100}%` }}></div>
                        </div>
                      </div>
                    ))
                  )}
                  {tabIndex === 10 && (
                    deliveryCounts.filter((e) => e.month === tabIndex).map((element) => (
                      <div key={element.key} className="progress-group">
                        {`${element.name} - ${element.branch}`}
                        <span className="float-right"><b>{element.inRange}</b>/{element.value}</span>
                        <div className="progress progress-sm">
                          <div className={`progress-bar bg-${element.brachColor}`} style={{ width: `${(element.inRange / element.value) * 100}%` }}></div>
                        </div>
                      </div>
                    ))
                  )}
                  {tabIndex === 11 && (
                    deliveryCounts.filter((e) => e.month === tabIndex).map((element) => (
                      <div key={element.key} className="progress-group">
                        {`${element.name} - ${element.branch}`}
                        <span className="float-right"><b>{element.inRange}</b>/{element.value}</span>
                        <div className="progress progress-sm">
                          <div className={`progress-bar bg-${element.brachColor}`} style={{ width: `${(element.inRange / element.value) * 100}%` }}></div>
                        </div>
                      </div>
                    ))
                  )}
                  {tabIndex === 12 && (
                    deliveryCounts.filter((e) => e.month === tabIndex).map((element) => (
                      <div key={element.key} className="progress-group">
                        {`${element.name} - ${element.branch}`}
                        <span className="float-right"><b>{element.inRange}</b>/{element.value}</span>
                        <div className="progress progress-sm">
                          <div className={`progress-bar bg-${element.brachColor}`} style={{ width: `${(element.inRange / element.value) * 100}%` }}></div>
                        </div>
                      </div>
                    ))
                  )}

                  {/* <!-- /.progress-group --> */}
                </div>
                {/* <!-- /.col --> */}
              </div>
              {/* <!-- /.row --> */}
            </div>
            <div className="card-footer">

              {deliveryCounts.filter((e) => e.month === tabIndex).filter((e) => (e.branch === "GEN")).map((element) => (
                <div className="row">
                  <div key={element.key} className="col-12 col-sm-6 col-md-3">
                    <div className="description-block border-right">
                      <span className="description-percentage text-info"><i className="fas fa-caret-up"></i> 100%</span>
                      <h5 className="description-header">{element.value}</h5>
                      <span className="description-text">TOTAL PEDIDOS</span>
                    </div>
                    {/* <!-- /.description-block --> */}
                  </div>
                  <div className="col-12 col-sm-6 col-md-3">
                    <div className="description-block border-right">
                      <span className="description-percentage text-success"><i className="fas fa-caret-up"></i> {`${((element.inRange / element.value) * 100).toFixed(2)}%`}</span>
                      <h5 className="description-header">{element.inRange}</h5>
                      <span className="description-text">TOTAL TIEMPO</span>
                    </div>
                    {/* <!-- /.description-block --> */}
                  </div>
                  <div className="col-12 col-sm-6 col-md-3">
                    <div className="description-block">
                      <span className="description-percentage text-danger"><i className="fas fa-caret-down"></i> {`${((element.outRange / element.value) * 100).toFixed(2)}%`}</span>
                      <h5 className="description-header">{element.outRange}</h5>
                      <span className="description-text">TOTAL TARDE</span>
                    </div>

                  </div>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="card">
            <div className="card-header bg-dark">
              <h5 className="card-title">Tiempo de ingreso de materiales</h5>


            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-8">
                  <p className="text-center">
                    <strong>Tiempos de almacen</strong>
                  </p>

                  <div className="chart" style={{ height: "80%" }}>
                    {/* <!-- Sales Chart Canvas --> */}
                    <Line
                      options={{
                        datasetFill: false
                      }}
                      data={
                        {
                          labels: performanceIndicators.filter((element) => element.kpi === "HTR").map((element) => `Sem ${element.week}`),
                          datasets: [
                            {
                              label: 'Eficiencia en la recepción',
                              backgroundColor: 'rgba(60,141,188,0.9)',
                              borderColor: 'rgba(60,141,188,0.8)',
                              pointRadius: false,
                              pointColor: '#3b8bba',
                              pointStrokeColor: 'rgba(60,141,188,1)',
                              pointHighlightFill: '#fff',
                              pointHighlightStroke: 'rgba(60,141,188,1)',
                              data: performanceIndicators.filter((element) => element.kpi === "HTR").map((element) => element.kpi_Value)
                            },
                          ]
                        }
                      }
                    />
                  </div>
                  {/* <!-- /.chart-responsive --> */}
                </div>
                {/* <!-- /.col --> */}
                <div className="col-md-4">
                  <p className="text-center">
                    <strong>Promedio de tiempo de recepcion</strong>
                  </p>

                  {performanceIndicators.filter((element) => element.kpi === "HTR").map((element) => (
                    <div key={element.key} className="progress-group">
                      {`Sem ${element.week}`}
                      <span className="float-right"><b>{element.kpi_Value}</b></span>
                      <div className="progress progress-sm">
                        <div className={`progress-bar bg-${element.brachColor}`} style={{ width: `${(element.kpi_Value)}%` }}></div>
                      </div>
                    </div>
                  ))}


                  {/* <!-- /.progress-group --> */}
                </div>
                {/* <!-- /.col --> */}
              </div>
              {/* <!-- /.row --> */}
            </div>

          </div>
        </Col>
      </Row>
    </>

  )
}

export default WarehousesHome