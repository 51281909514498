import { useQuery } from '@apollo/client'
import React, { useState, useEffect } from 'react'
import { GET_CALENDAR_PURCHASE_ORDER } from '../../graphql/Contexts/Calendar'
import { Col, Row } from 'reactstrap'
import CalendarApp from '../Layout/CalendarApp'
import CardBox from '../Layout/CardBox'

const QualityManagementHome = () => {
    const [events, setEvents] = useState([])

    const { loading: loadingCalendar, error: errorCalendar, data: dataCalendar } = useQuery(GET_CALENDAR_PURCHASE_ORDER, {
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        if (!loadingCalendar) {
            if (errorCalendar) {
                AlertApp({ type: 'errorToast', message: errorCalendar })
            } else {
                setEvents(dataCalendar.calendarPurchaseOrders)

            }
        }
    }, [loadingCalendar, dataCalendar, errorCalendar])
    return (
        <>
            <CardBox
                title={"Calendario de Andenes (Compras)"}
                loading={loadingCalendar}
                content={<Row>
                    <Col>
                        <CalendarApp data={events} />
                    </Col>
                </Row>}
            />
        </>
    )
}

export default QualityManagementHome