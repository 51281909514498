import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DashboardLayout } from '../../Layout/DashboardLayout';
import { Button, Col, Row, ButtonGroup, Card, CardBody, CardTitle, CardHeader } from 'reactstrap';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import whitReactContent from 'sweetalert2-react-content'

import DataTable from 'react-data-table-component';
import moment from 'moment'
import { DocStatesSBO } from '../../../Types';
import ActionButtonsForRows from '../../Layout/ActionButtonsForRows';
import CardBox from '../../Layout/CardBox';
import { AlertApp } from '../../Global/AlertApp';
import { useForm } from 'react-hook-form';
import InputApp from '../../Layout/InputApp';

// apollo
import { GET_PURCHASE_ORDER } from '../../../graphql/QueryManager';
import { SUBSCRIPTION_COMMODITIES_RECEIPTS_CONTENT_ADDED, SUBSCRIPTION_COMMODITIES_RECEIPTS_FINISH, ADD_BATCH_INFORMATION_ORDER, DELETE_BATCH_INFORMATION_ORDER, UPDATE_BATCH_INFORMATION_ORDER,GET_ALL_BATCH_INFORMATION_ORDER } from '../../../graphql/Catalogs/MerchandiseReception/CommoditiesReceipt';
import DataTableApp from '../../Layout/DataTableApp';
import BatchInformationDialog from './BatchInformationDialog';

const PurchaseOrderNew = () => {
    const { id: _docEntry, show: _show } = useParams()
    const navigate = useNavigate()
    const [purchaseOrder, setPurchaseOrder] = useState([])
    const [content, setContent] = useState([])
    const [batchInfo, setBatchInfo] = useState(false)
    const [batchNumberInformation, setBatchNumberInformation] = useState([{ distNumber: '' }])
    const [selectedRow, setSelectedRow] = useState({})
    const mySwal = whitReactContent(Swal)
    const { control, reset } = useForm()
    const { loading, data, error, refetch } = useQuery(GET_PURCHASE_ORDER, {
        variables: {
            docEntry: parseInt(_docEntry)
        },
        fetchPolicy: 'cache-and-network'
    })
    const { loading: loadingBatchInfo, data: dataBatchInfo, error: errorBatchInfo, refetch: refetchBatchInfo } = useQuery(GET_ALL_BATCH_INFORMATION_ORDER, {
        variables: {
            docEntry: _docEntry ? parseInt(_docEntry) : undefined
        }
    })

    const validationSchema = Yup.object().shape({

    })
    const { data: dataEvent, error: errorEvent, loading: loadingEvent } = useSubscription(SUBSCRIPTION_COMMODITIES_RECEIPTS_CONTENT_ADDED)
    const { data: dataFinished, error: errorFinished, loading: loadingFinished } = useSubscription(SUBSCRIPTION_COMMODITIES_RECEIPTS_FINISH)

    const [addBatchInfo, { loading: progressAddBatchInfo }] = useMutation(ADD_BATCH_INFORMATION_ORDER)
    const [deleteBatchInfo, { loading: progressDeleteBatchInfo }] = useMutation(ADD_BATCH_INFORMATION_ORDER)
    const [updateBatchInfo, {loading: progressUpdateBatchInfo}] = useMutation(UPDATE_BATCH_INFORMATION_ORDER)

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({ type: 'errorToast', message: error })
            }

            setPurchaseOrder(data.onePurchaseOrder)
            reset(data.onePurchaseOrder)
            let list = []

            data.onePurchaseOrder.content.map((element) => {
                let buttons = <ActionButtonsForRows
                    baseUrl={'shopping/purchaseorders'}
                    element={element}

                    batchInformationButton
                    batchInformationAction={() => ShowBatchInformationButton(element)}
                />

                return list.push({
                    id: element.lineNum,
                    lineNum: element.docEntry,
                    itemCode: element.itemCode,
                    dscription: element.dscription,
                    quantity: element.quantity,
                    openQty: element.openQty,
                    unitMsr: element.unitMsr,
                    u_Sucursal: element.warehouse.u_Sucursal,
                    whsCode: element.whsCode,
                    receipts: data.onePurchaseOrder.receipts.filter(
                        (item) =>
                            item.baseEntry == element.docEntry && item.itemCode == element.itemCode
                    ),
                    batchInfo: data.onePurchaseOrder.batchNumberInformation.filter(
                        (item) =>
                            item.lineNum == element.lineNum
                    ),
                    actions: buttons
                })
            })

            setContent(list)

        }
    }, [loading, error, data])

    useEffect(() => {
        if (!loadingBatchInfo) {
            if (errorBatchInfo) {
                AlertApp({ type: 'errorToast', message: error })
            }
            setBatchNumberInformation(dataBatchInfo.allBatchNumberInformation)

        }
    }, [loadingBatchInfo, dataBatchInfo, errorBatchInfo])

    useEffect(() => {
        if (!loadingEvent)
            if (errorEvent) {
                AlertApp({ type: 'errorToast', message: errorEvent })
            }
        if (dataEvent) {
            setPurchaseOrder(dataEvent.commodityReceiptContentAdded)
            let list = []

            dataEvent.commodityReceiptContentAdded.content.map((element) => {
                let buttons = <ActionButtonsForRows
                    baseUrl={'shopping/purchaseorders'}
                    element={element}
                    qualityCertificateButton
                />

                return list.push({
                    id: element.lineNum,
                    lineNum: element.docEntry,
                    itemCode: element.itemCode,
                    dscription: element.dscription,
                    quantity: element.quantity,
                    openQty: element.openQty,
                    unitMsr: element.unitMsr,
                    u_Sucursal: element.warehouse.u_Sucursal,
                    whsCode: element.whsCode,
                    receipts: dataEvent.commodityReceiptContentAdded.receipts.filter(
                        (item) =>
                            item.baseEntry === element.docEntry && item.itemCode == element.itemCode
                    ),
                    batchInfo: dataEvent.commodityReceiptContentAdded.batchNumberInformation.filter(
                        (item) =>
                            item.lineNum === element.lineNum
                    ),
                    actions: buttons
                })
            })

            setContent(list)

        }
    }, [loadingEvent, errorEvent, dataEvent])

    useEffect(() => {
        if (!loadingFinished)
            if (errorFinished) {
                AlertApp({ type: 'errorToast', message: errorFinished })
            }
        if (dataFinished) {
            if (dataFinished.purchaseOrderReceiptComplete.id == _docEntry) {
                dataFinished.purchaseOrderReceiptComplete.docStatus = "C"
                setPurchaseOrder(dataFinished.commodityReceiptFinish)
                let list = []

                dataFinished.purchaseOrderReceiptComplete.content.map((element) => {
                    let buttons = <ActionButtonsForRows
                        baseUrl={'shopping/purchaseorders'}
                        element={element}
                        qualityCertificateButton
                    />

                    return list.push({
                        id: element.lineNum,
                        lineNum: element.docEntry,
                        itemCode: element.itemCode,
                        dscription: element.dscription,
                        quantity: element.quantity,
                        openQty: element.openQty,
                        unitMsr: element.unitMsr,
                        u_Sucursal: element.warehouse.u_Sucursal,
                        whsCode: element.whsCode,
                        receipts: dataFinished.purchaseOrderReceiptComplete.receipts.filter(
                            (item) =>
                                item.baseEntry == element.docEntry
                        ),
                        batchInfo: element.batchNumberInformation.receipts.filter(
                            (item) =>
                                item.lineNum == element.lineNum
                        ),
                        actions: buttons
                    })
                })

                setContent(list)
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    }
                });
                Toast.fire({
                    icon: "success",
                    title: `Se finalizo la recepcion de la orden de compra`
                });
            }
        }
    }, [loadingFinished, errorFinished, dataFinished])


    const columns = [
        {
            name: '#',
            selector: row => row.lineNum,
            sortable: false,
            width: '80px'
        },
        {
            name: 'Número de artículo',
            selector: row => row.itemCode,
            sortable: false,
        },
        {
            name: 'Descripción del artículo',
            selector: row => row.dscription,
            sortable: false,
            grow: 5,
        },
        {
            name: 'Cantidad',
            selector: row => row.quantity,
            sortable: false,
        },
        {
            name: 'Cantidad pendiente',
            selector: row => row.openQty,
            sortable: false,
        },
        {
            name: 'UM',
            selector: row => row.unitMsr,
            sortable: false,
        },
        {
            name: 'Almacén',
            selector: row => row.whsCode,
            sortable: false,
        },
        {
            name: 'Sucursal',
            selector: row => row.u_Sucursal,
            sortable: false,
        },
        {
            name: '',
            selector: row => row.actions,
            sortable: false,
        },
    ]

    const columnsExpandable = [
        {
            name: '#',
            selector: row => row.id,
            sortable: false,
            width: '80px',
        },
        {
            name: 'Escaneado',
            selector: row => row.quantity,
            sortable: false,
        },
        {
            name: 'Lote / Num Serie',
            selector: row => row.lotNumber,
            sortable: false,
        },
        {
            name: 'Validado',
            selector: row => batchNumberInformation.some((element) => element.distNumber == row.lotNumber),
            sortable: false,
        }
    ]
    const columnsBatch = [
        {
            name: 'Lote / Num Serie',
            selector: row => row.distNumber,
            sortable: false,
            width: '150px',
        },
        {
            name: 'Fecha de expiración',
            selector: row => moment(row.expDate).format('YYYY-MM-DD'),
            sortable: false,
        },
        {
            name: 'Cantidad',
            selector: row => row.quantity,
            sortable: false,
        },
        {
            name: 'Cantidad aprobada',
            selector: row => row.commitQty,
            sortable: false,
        },
        {
            name: 'COA',
            selector: row => row.attached1,
            sortable: false,
        },

    ]

    const addButtonsTools = () => {
        return (
            <>
                <ButtonGroup>
                    <button type="button" className={"btn btn-sm btn-secondary"}>Recepcion</button>
                    <button type="button" className="btn btn-sm btn-secondary dropdown-toggle dropdown-icon" data-toggle="dropdown">
                        <span className="sr-only">Toggle Dropdown</span>
                    </button>
                    <div className="dropdown-menu" role="menu">
                        <button className="dropdown-item" role="menuitem" onClick={() => onSubmit()}>Finalizar</button>
                        <button className="dropdown-item" role="menuitem" >Reiniciar</button>
                    </div>
                </ButtonGroup>
            </>
        )
    }

    const ShowBatchInformationButton = async (item) => {
        setBatchInfo(true)
        setSelectedRow(item)
    }

    const ToogleDetailReceipt = ({ data }) => {
        return <>
            <Row>
                <Col md={5}>
                    <DataTable
                        data={data.receipts}
                        columns={columnsExpandable}
                        dense
                        striped
                    />
                </Col>
                <Col md={6}>
                    <Card className={"card-secondary"}>
                        <CardHeader ><CardTitle>Documentacion de proveedor</CardTitle></CardHeader>                        
                        <CardBody>
                            <DataTable
                                data={data.batchInfo}
                                columns={columnsBatch}
                                dense
                                striped
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    }

    const onSubmit = async (data) => {
        try {

        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        }
    }
    return (
        <DashboardLayout>
            <CardBox
                title={`Orden de compra ${purchaseOrder.docNum}`}
                loading={loading}
                footer
                btnReturn={() => navigate(-1)}
                actionTools={addButtonsTools()}

                content={<>
                    <Row>
                        <Col >
                            <Row>
                                <Col lg={4}>
                                    <InputApp name={"cardCode"} label={"Proveedor"} control={control} isReadOnly />
                                </Col>
                                <Col lg={8}>
                                    <InputApp name={"cardName"} label={"Nombre"} control={control} isReadOnly />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <InputApp name={"indicatorName"} label={"Area"} control={control} isReadOnly />
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col lg={3}>
                                    <InputApp name={"docStatusDesc"} label={"Estatus"} control={control} isReadOnly defaultValue={DocStatesSBO[purchaseOrder.docStatus]} />
                                </Col>
                                <Col lg={3}>
                                    <InputApp name={"docNum"} label={"No"} control={control} isReadOnly />
                                </Col>
                                <Col lg={6}>
                                    <InputApp name={"numAtCard"} label={"No.Ref.del acreedor"} control={control} isReadOnly={_show} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputApp name={"currency"} label={"Moneda"} control={control} isReadOnly={_show} />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={{ size: 3, offset: 6 }}>
                                    <InputApp name={"docDate"} label={"Fecha de contabilización"} inputType='date' control={control} isReadOnly />
                                </Col>
                                <Col lg={{ size: 3, offset: 0 }}>
                                    <InputApp name={"docDueDate"} label={"Fecha de entrega"} inputType='date' control={control} isReadOnly />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DataTableApp
                                data={content}
                                columns={columns}
                                striped
                                dense
                                expandableRows
                                expandableRowsComponent={ToogleDetailReceipt}

                            />

                        </Col>
                    </Row>

                </>}
            />
            <BatchInformationDialog
                _order={purchaseOrder}
                _item={selectedRow}
                _addRow={addBatchInfo}
                _updateRow={updateBatchInfo}
                _rows={batchNumberInformation}
                _dialog={batchInfo}
                _setDialog={setBatchInfo}
                _progress={progressDeleteBatchInfo|| progressDeleteBatchInfo || progressAddBatchInfo}
                
                _reload={refetchBatchInfo} />
        </DashboardLayout>
    );
};

export default PurchaseOrderNew;